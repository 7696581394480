import PageWrapper from "../../components/PageWrapper";
import Seo from "../../components/Seo/seo";
import React from "react";
import Hero from "../../sections/blog/wpt/Hero";
import {Box, Button, Section, Text, Title} from "../../components/Core";

import acceptedCountriesPdf from "../../assets/pdf/WPT_List_of_all_accepted_countries.pdf";

import firstImage from "../../assets/image/flop/event/wpt/section01_illustration 4.png";
import footerImage from "../../assets/image/flop/event/wpt/section03_illustration 1.png";
import secondImage from "../../assets/image/flop/event/wpt/section02_illustration 3.png";
import {Container} from "reactstrap";

const WptBlogPage = () => {
    return (
        <PageWrapper headerDark bg={'black'}>
            <Seo
                title="FLOP Celebrates WPT Global Affiliation with $10k Freeroll"
                desc="First Land of Poker has recently become an affiliate of the new WPT Global online poker site and will mark this occasion by hosting a $10,000 Freeroll on June 7th. The new online poker site extends the reach of the WPT brand to poker fans and players in over 80 countries."
            />
            <Hero/>
            <Section pt={[0, 0, 0, 0, 100]} py={0} pb={80}>
                <Container>
                    <Box pl={[0, 0, 0, 0, 0]}>
                        <article>
                            <Title style={{maxWidth: 1000, textAlign: 'left', margin: '', fontSize: 38}}>
                                Play against Patrik Antonius on WPT Global
                            </Title>
                            <section className="d-flex align-items-start flex-wrap">
                                <div style={{flex: '1'}}>
                                    <div>
                                        <Text mb={20} fontSize={24} lineHeight={1.4}>
                                            First Land of Poker will host regular online meet-up-cash cash games and
                                            tournaments for FLOP players on WPT Global, which Patrik will play in,
                                            when his schedule permits.
                                        </Text>
                                        <Text mb={20} fontSize={24} lineHeight={1.4}>
                                            The first opportunity to take on Patrik is a <strong>Patrik Antonius $10,000 Freeroll</strong>
                                            on Tuesday June 7th, at 8pm (CET)/2pm (EST) open to all players who sign up
                                            to WPT Global using the FLOP affiliate link and register using the code <strong>‘FLOP’</strong>:&nbsp;
                                            <a href={"https://wlwptonline.adsrv.eacdn.com/C.ashx?btag=a_18b_75c_&affid=16&siteid=18&adid=75&c="} target="_blank">
                                                <u><i>Affiliate Link</i></u>
                                            </a>
                                        </Text>
                                        <Text mb={20} fontSize={24} lineHeight={1.4}>
                                            A $1,000 bounty has been placed on Patrik’s head in this tournament and
                                            will go to the player who eliminates him. There is also a bonus for the
                                            winner of this Freeroll, as they will get to play in a special tournament
                                            on June 12th, alongside Patrik and many other WPT affiliates, with $2,000
                                            bounties on all the celebs/affiliates heads and a winner takes all $100,000
                                            prize pool!
                                        </Text>
                                    </div>
                                </div>
                            </section>
                            <Title style={{maxWidth: 1000, textAlign: 'left', margin: '', fontSize: 38}}>
                                How to take part and take on Patrik!
                            </Title>
                            <section className="d-flex align-items-start flex-wrap">
                                <div style={{flex: '1'}}>
                                    <div>
                                        <Text mb={20} fontSize={24} lineHeight={1.4}>
                                            Simply download WPT Global after clicking on this link, register using
                                            the code ‘FLOP” and you will receive a ticket to play in the Patrik Antonius
                                            $10,000 Freeroll. No deposit is required to take part in this freeroll,
                                            but if you do, you will also receive a matched deposit bonus up to $1,200
                                            (conditions apply, see below). Please note this offer is only available to
                                            players from the eligible countries list.
                                        </Text>
                                    </div>
                                </div>
                            </section>
                            <Title style={{maxWidth: 1000, textAlign: 'left', margin: '', fontSize: 38}}>
                                WPT Global Highlights...
                            </Title>
                            <Text mb={20} fontSize={28} lineHeight={1.4}><i>Gameplay</i></Text>
                            <section className="d-flex align-items-start flex-wrap">
                                <div style={{flex: '1'}}>
                                    <div>
                                        <Text mb={20} fontSize={24} lineHeight={1.4}>
                                            Simply download WPT Global after clicking on&nbsp;
                                            <a href={"https://wlwptonline.adsrv.eacdn.com/C.ashx?btag=a_18b_75c_&affid=16&siteid=18&adid=75&c=" } target="_blank">
                                                <u>this link</u>
                                            </a>
                                            , register using
                                            the code ‘FLOP” and you will receive a ticket to play in the Patrik Antonius
                                            $10,000 Freeroll. No deposit is required to take part in this freeroll,
                                            but if you do, you will also receive a matched deposit bonus up to $1,200
                                            (conditions apply, see below). Please note this offer is only available to
                                            players from the eligible countries list.
                                        </Text>
                                    </div>
                                </div>
                            </section>
                            <Text mb={20} fontSize={28} lineHeight={1.4}><i>Live Event Satellites</i></Text>
                            <section className="d-flex align-items-start flex-wrap">
                                <div style={{flex: '1'}}>
                                    <div>
                                        <Text mb={20} fontSize={24} lineHeight={1.4}>
                                            It is also the home of online satellites to many live WPT events including
                                            WPT Main Tour, WPT Prime and many more. We may even have satellites to future
                                            PAPC events on WPT Global at some point...check this blog regularly for
                                            further updates.
                                        </Text>
                                    </div>
                                </div>
                            </section>
                            <Text mb={20} fontSize={28} lineHeight={1.4}><i>Matched Deposit Sign Up Bonus</i></Text>
                            <section className="d-flex align-items-start flex-wrap">
                                <div style={{flex: '1'}}>
                                    <div>
                                        <Text mb={20} fontSize={24} lineHeight={1.4}>
                                            Open to new depositors from eligible countries, depositing between $20-$1,200.
                                            Play real money cash games or tournaments to unlock the deposit, which will
                                            be released in blocks of $5 (for every $20 raked). Valid for 90-days after deposit.
                                            Full Terms and Conditions on the&nbsp;
                                            <a href={"https://wlwptonline.adsrv.eacdn.com/C.ashx?btag=a_18b_75c_&affid=16&siteid=18&adid=75&c="} target="_blank">
                                                <u>WPT Global website</u>
                                            </a>.
                                        </Text>
                                    </div>
                                </div>
                            </section>
                            <Title style={{maxWidth: 1000, textAlign: 'left', margin: '', fontSize: 38}}>
                                Who can sign up?
                            </Title>
                            <section className="d-flex align-items-start flex-wrap">
                                <div style={{flex: '1'}}>
                                    <div>
                                        <Text mb={20} fontSize={24} lineHeight={1.4}>
                                            WPT Global is available in 85 countries, with further territories expected
                                            to be added in the near future. In Europe, WPT Global is currently available
                                            to players from Andorra, Bosnia & Herzegovina, Croatia, Finland, Georgia,
                                            Hungary, Iceland, Ireland, Liechtenstein, Luxembourg, Moldova, Monaco,
                                            Montenegro, North Macedonia, Poland, Slovakia and Slovenia.
                                        </Text>
                                        <Text mb={20} fontSize={24} lineHeight={1.4}>
                                            <i>
                                                First Land of Poker is an affiliate of WPT Global and may earn a fee if you&nbsp;
                                                <a href={"https://wlwptonline.adsrv.eacdn.com/C.ashx?btag=a_18b_75c_&affid=16&siteid=18&adid=75&c="} target="_blank">
                                                    <u>sign up</u>
                                                </a>
                                                &nbsp;and play after clicking on the links included in this blog post.
                                            </i>
                                        </Text>
                                    </div>
                                </div>
                            </section>
                        </article>
                    </Box>
                </Container>
            </Section>
        </PageWrapper>
    );
};
export default WptBlogPage;
