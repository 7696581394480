import React from "react";
import styled from "styled-components";
import {Row, Col} from "react-bootstrap";
import {Title, Button, Section, Box, Text} from "../../../components/Core";
import wptGlobalDetailsPng from "../../../assets/image/flop/blog/WPT_Global_details.png";
import {Container} from "reactstrap";

const ImgRight = styled.img`
  width: 100%;
`;

const Hero = () => {
    return (
        <Section pt={100} py={0} pb={20} bg="#0f1012" className="position-relative">
            <Container>
                <Box>
                    <Row className="justify-content-center align-items-center">
                        <Col lg="6" md="10" sm="9" className="order-lg-2">
                            <Row className={'justify-content-end'}>
                                <div className="text-center text-lg-right position-relative">
                                    <div
                                        className="img-main"
                                        data-aos="fade-down"
                                        data-aos-duration="750"
                                        data-aos-once="true"
                                        data-aos-delay="500"
                                    >
                                        <ImgRight
                                            src={wptGlobalDetailsPng}
                                            alt="WPT Presentation"
                                        />
                                    </div>
                                </div>
                            </Row>
                        </Col>
                        <Col lg="6" className="order-lg-1" style={{paddingLeft: 20, paddingRight: 20}}>
                            <div
                                data-aos="fade-right"
                                data-aos-duration="750"
                                data-aos-once="true"
                                data-aos-delay="500"
                            >
                                <Box pr={5} pt={[null]}>
                                    <Title color="light">
                                        FLOP Celebrates WPT Global Affiliation with $10k Freeroll
                                    </Title>
                                    <Text color="light" mb={10}>First Land of Poker has recently become an affiliate
                                        of the new <a href={"https://wlwptonline.adsrv.eacdn.com/C.ashx?btag=a_18b_75c_&affid=16&siteid=18&adid=75&c="} target="_blank" >
                                            <u style={{color: 'white'}}>WPT Global online poker site</u></a> and will mark this occasion by hosting
                                        a $10,000 Freeroll on June 7th. The new online poker site extends the reach of
                                        the WPT brand to poker fans and players in over 80 countries.
                                    </Text>

                                </Box>
                            </div>
                        </Col>
                    </Row>
                </Box>
            </Container>
        </Section>
    );
};

export default Hero;
